import * as React from "react";
import { Divider, List } from "@mui/material";
import { I18n } from "react-redux-i18n";

import { IUpwardForecastHistoryItem } from "core/api/rest/queries/upward-forecast-history";
import HistoryListItem from "./history-list-item";
import { uniqueId } from "lodash";

interface IHistoryListProps {
  items: IUpwardForecastHistoryItem[];
}

const HistoryList: React.FunctionComponent<IHistoryListProps> = React.memo(({ items }) => {
  const historySize = items?.length;
  const exceptionsLastIndex = historySize ? historySize - 1 : null;
  return historySize ? (
    <List>
      {items.map((item, index) => {
        const showDivider = index !== exceptionsLastIndex;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <React.Fragment key={`${item.activated}_${item.date}_${uniqueId()}`}>
            <HistoryListItem date={item.date} activated={item.activated} />
            {showDivider ? <Divider variant="inset" component="li" /> : null}
          </React.Fragment>
        );
      })}
    </List>
  ) : (
    <span>{I18n.t("upward_forecast.no_history")}</span>
  );
});

export default HistoryList;
