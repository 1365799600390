import * as React from "react";
import classNames from "classnames";
import {
  Table,
  ITableProps,
  TableInteractionsContext,
  IVirtualizerProps as ITableVirtualizerProps,
  MutableRefComponent
} from "@decathlon/react-table";

import { getTableSize } from "core/constants/table";
import { IDataCoordinates } from "core/utils/cells";
import ActionButtonsParentComponent from "components/common/mygame/action-buttons-area/action-buttons-parent";

const FIXED_ROWS = [0, 1];
export const FIXED_COLUMNS = [2];

type IVirtualizerProps = Required<
  Pick<
    ITableVirtualizerProps,
    "minColumnWidth" | "minRowHeight" | "initialScroll" | "fixedColumns" | "fixedRows" | "onHorizontallyScroll"
  >
>;

interface IProps {
  /** The table props. */
  tableProps: Pick<ITableProps<IDataCoordinates>, "id" | "rows" | "columns">;
}

interface IDumbProps extends IProps {
  /** The table ref. */
  tableRef: MutableRefComponent<Table>;
  virtualizerProps: IVirtualizerProps;
  onTableUpdate: () => void;
  className?: string;
}

const DumbUpwardForecastPilotingTable: React.FunctionComponent<IDumbProps> = ({
  tableProps,
  virtualizerProps,
  tableRef,
  onTableUpdate,
  className
}) => {
  return (
    <div className="piloting-table upward-forecast-table">
      <div className={classNames("table-container", className)}>
        <ActionButtonsParentComponent
          childrenLeft={
            <Table
              ref={tableRef}
              {...tableProps}
              isSelectable={false}
              isVirtualized
              virtualizerProps={{
                ...virtualizerProps,
                ...getTableSize()
              }}
              onOpenedTreesUpdate={onTableUpdate}
            />
          }
        />
      </div>
    </div>
  );
};

const UpwardForecastPilotingTable: React.FunctionComponent<IProps> = props => {
  return (
    <TableInteractionsContext.Consumer>
      {({ cellWidth, rowHeight, tableRef, onHorizontallyScroll, columnsCursor, onTableUpdate }) => {
        return (
          <DumbUpwardForecastPilotingTable
            tableRef={tableRef}
            {...props}
            virtualizerProps={{
              fixedColumns: FIXED_COLUMNS,
              fixedRows: FIXED_ROWS,
              minColumnWidth: cellWidth.value,
              minRowHeight: rowHeight.value,
              initialScroll: {
                columnIndex: columnsCursor ? columnsCursor.index : null
              },
              onHorizontallyScroll
            }}
            onTableUpdate={onTableUpdate}
          />
        );
      }}
    </TableInteractionsContext.Consumer>
  );
};

export default UpwardForecastPilotingTable;
