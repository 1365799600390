import { useEffect } from "react";
import { createInstance, Identify } from "@amplitude/analytics-browser";
import { UserInfo } from "./useAuthUserInfo";
import { ENV_PROD } from "../constants/datadog";

const PRODUCTION_ENVIRONMENT = import.meta.env.REACT_APP_ENV === ENV_PROD;
const AMPLITUDE_API_KEY = import.meta.env.REACT_APP_AMPLITUDE_API_KEY;

let amplitudeInstance = null;

const initializeAmplitude = (user: UserInfo) => {
  if (PRODUCTION_ENVIRONMENT && user?.uid && !amplitudeInstance) {
    amplitudeInstance = createInstance();
    amplitudeInstance.init(AMPLITUDE_API_KEY, user.uid, { autocapture: true, serverZone: "EU" });
    amplitudeInstance.setUserId(user.uid);
  }
};

export const useAmplitude = (user: UserInfo) => {
  useEffect(() => {
    initializeAmplitude(user);
    if (amplitudeInstance) {
      const identifyEvent = new Identify();
      identifyEvent.set("sitename", user.sitename ?? "not-available");
      identifyEvent.set("job_name", user.jobname ?? "not-available");
      amplitudeInstance.identify(identifyEvent);
    }
  }, [user]);
};

export const logAmplitudeEvent = (eventName: string) => amplitudeInstance?.logEvent(eventName);
