import { useEffect } from "react";
import ReactGA from "react-ga4";
import IApiUserData from "../models/api-user-data";
import { ENV_PROD } from "../constants/datadog";
import { RoleName } from "../utils/user";
import { datadogRum } from "@datadog/browser-rum";
import { logAmplitudeEvent } from "./useAmplitude";

const PRODUCTION_ENVIRONMENT = import.meta.env.REACT_APP_ENV === ENV_PROD;
type AnalyticsEvent = string;

export const useAnalytics = (user: IApiUserData, roleName: RoleName) => {
  useEffect(() => {
    if (user?.uid && PRODUCTION_ENVIRONMENT) {
      if (!ReactGA.isInitialized) ReactGA.initialize(import.meta.env.REACT_APP_GA_TRACKING_ID);
      const userProperties: Record<string, string> = {};
      if (user?.preferences?.default_filters?.country)
        userProperties.user_country = user.preferences.default_filters.country;
      if (roleName && roleName != "NOT-SET") userProperties.user_role = roleName;
      if (user.job_name) userProperties.job_name = user.job_name;

      ReactGA.gtag("set", "user_id", user.uid);
      ReactGA.gtag("set", "user_properties", userProperties);
    }
  }, [user, roleName]);
};

export const trackAnalyticsEvent = (action: AnalyticsEvent) => {
  ReactGA.event(action, { value: 1 });
  datadogRum.addAction(action, {
    value: 1
  });
  logAmplitudeEvent(action);
};
