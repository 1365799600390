import * as React from "react";

import ToolbarSection from "components/common/toolbar/toolbar-section";
import Toolbar from "components/common/toolbar/toolbar";
import SelectCountry from "components/specific/upward-forecast/select-country";
import ActivateButton from "./data-manager/actions/activate-button";
import HistoryButton from "./data-manager/actions/history/history-button";
import UnblockButton from "./data-manager/actions/unblock-button";
import UpwardForecastMessage from "./upward-forecast-message";
import { IFilters } from "core/redux/filters/reducers";

const UpwardForecastTableToolbar: React.FC<{ country: string; setFilters: (filters: Partial<IFilters>) => void }> = ({
  country,
  setFilters
}) => {
  return (
    <div className="mg-filters-bar">
      <div className="mg-filters-bar__filters-area">
        <SelectCountry value={country} onChange={(country: string) => setFilters({ country })} />
        <div className="mg-filters-bar__upward">
          <HistoryButton isToolbarAction isDisabled={!country} />
          <UpwardForecastMessage />
        </div>
      </div>
      <div className="mg-filters-bar__apply-area">
        <Toolbar className="small">
          <ToolbarSection>
            <ActivateButton isDisabled={!country} country={country} />
          </ToolbarSection>
          <ToolbarSection dense>
            <UnblockButton isDisabled={!country} country={country} />
          </ToolbarSection>
        </Toolbar>
      </div>
    </div>
  );
};

export default React.memo(UpwardForecastTableToolbar);
